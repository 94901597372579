/* show ol styles in builder disabled by tailwind */
.builder-block > span > ol,
.builder-block > p > ol {
  list-style: decimal;
  padding-left: 22px;
}

/* show ul styles in builder disabled by tailwind */
.builder-block > p > ul li::before,
.builder-block > span > ul li::before {
  content: '\2022';
  color: var(--wh-color_mauve-600);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

/* cap max with on builder blocks */
.builder-block {
  max-width: 100%;
}

@media (width >= 640px) {
  .builder-block {
    max-width: 100vw;
    box-sizing: border-box !important;
  }
}

.market-card > a > div:nth-child(2) {
  min-height: 342px;
}
